<template>
  <b-card>
    <h2>Relatório de lançamentos</h2>
    <validation-observer ref="formPesquisar">
      <b-form>
        <b-row>
          <b-col md="2">
            <b-form-group label="Mês" label-for="mes">
              <b-form-select id="mes" :options="mesOpcoes" v-model="pesquisar.mes" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Ano" label-for="ano">
              <v-select-pt name="ano" v-model="pesquisar.exercicio" :options="anoOpcao" label="exercicio"></v-select-pt>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Status" label-for="status">
              <b-form-select id="status" :options="statusOpcoes" v-model="pesquisar.statusOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Órgão" label-for="orgao">
              <v-select-pt
                name="orgao"
                v-model="pesquisar.orgao"
                :options="orgaoOpcao"
                :reduce="(option) => option.id"
                @option:selected="carregarGrid()"
                label="nome"
                v-on:keydown.enter="carregarGrid"
              >
              </v-select-pt>
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="pesquisarDados()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto">
            <template>
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-top: 21px; width: 160px">
                    Exportar
                  </b-button>
                </template>
                <b-dropdown-item href="#" @click="exportTable('pdf')">
                  <feather-icon icon="FileTextIcon" size="16" />
                  <span class="align-middle ml-50">PDF</span>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="exportTable('excel')">
                  <feather-icon icon="CheckCircleIcon" size="16" />
                  <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div v-if="items.length > 0" style="margin-top: 0.6rem">
      <b-row>
        <b-col md="5">
          <p>
            Valor das Parcelas: <b-badge variant="primary"> {{ this.totalParcela }} </b-badge>
          </p>
        </b-col>
      </b-row>
    </div>

    <b-table
      v-show="items.length > 0"
      striped
      responsive
      id="tableLancamento"
      ref="tableLancamento"
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(createdAt)="row">
        {{ formatarData(row.item.createdAt) }}
      </template>

      <template #cell(statusLancamento)="finanrow">
        <b-badge pill :variant="statusLancamento[0][finanrow.item.statusLancamento]">
          {{
            finanrow.item.statusLancamento == 'Lancado'
              ? 'Lançado'
              : finanrow.item.statusLancamento == 'Antecipado por Óbito'
              ? 'Antecipado por Óbito'
              : finanrow.item.statusLancamento == 'Antecipado'
              ? 'Antecipado'
              : 'Não Lançado'
          }}
        </b-badge>
      </template>

      <template #cell(statusDesconto)="finanrow">
        <b-badge pill :variant="statusDesconto[0][finanrow.item.statusDesconto]">
          {{
            finanrow.item.statusDesconto == 'NaoDescontada'
              ? 'Parcela Não Descontada'
              : finanrow.item.statusDesconto == 'Descontado'
              ? 'Parcela Descontada'
              : finanrow.item.statusDesconto == 'Rejeitada'
              ? 'Parcela Rejeitada'
              : finanrow.item.statusDesconto == 'Renegociada'
              ? 'Parcela Renegociada'
              : finanrow.item.statusDesconto == 'PagaParcialmente'
              ? 'Parcela Paga Parcialmente'
              : ''
          }}
        </b-badge>
      </template>

      <template #cell(orgao)="row">
        {{ row.item.orgao }}
      </template>
    </b-table>

    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <div>
      <relatorioFinanceiroLancamentoRetrato ref="exportarPDF" :parametro="parametroPDF" />
    </div>
  </b-card>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarValorReais } from '@/libs/utils'
  import { getNextMonthAndYear } from '@/utils/Functions/Datas/Datas'

  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import relatorioFinanceiroLancamentoRetrato from './components/relatorioFinanceiroLancamentoRetrato.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import { asDefault } from './components/exportTableLancamentos'
  import { date } from 'fp-ts'
  import { sortData } from '@/utils/geradorPDF/sortMethod'

  export default {
    components: {
      relatorioFinanceiroLancamentoRetrato,
      BCard,
      BPagination,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      BDropdown,
      BDropdownItem,
      VueHtml2pdf,
      BCardCode,
      formatarData,
      generateAndDownloadPdf,
      formatarValorReais,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        totalParcela: 0,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarData,
        isBusy: false,
        //Search
        pesquisar: {
          tipoOpcoes: 1,
          perPage: 100,
          currentPage: 1,
          mes: null,
          exercicio: null,
          statusOpcoes: '',
          orgao: null,
        },
        orgaoOpcao: [],
        anoOpcao: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Descontado', text: 'Parcelas Descontadas' },
          { value: 'NaoDescontada', text: 'Parcelas Não Descontadas' },
          { value: 'Rejeitada', text: 'Parcelas à Regularizar' },
        ],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },

        //table
        pageOptions: [10, 25, 50, 100],
        items: [],
        totalRows: 0,
        fields: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value) => formatarData(value),
            sortable: true,
          },
          { key: 'codigo', label: 'N° do Contrato', sortable: true },
          {
            key: 'valor',
            label: 'Valor da Parcela',
            formatter: (value) => formatarValorReais(value),
            sortable: true,
          },
          {
            key: 'vencimento',
            label: 'Data de vencimento',
            formatter: (value) => formatarData(value),
            sortable: true,
          },
          { key: 'statusLancamento', label: 'Status Lancamento', sortable: true },
          { key: 'statusDesconto', label: 'Status da Parcela', sortable: true },
          { key: 'nomeCompleto', label: 'Nome do Tomador', sortable: true },
          { key: 'matricula', label: 'Matrícula ', sortable: true },
          { key: 'numeroParcelas', label: 'Parcela ', sortable: true },
          { key: 'orgao', label: 'Órgão', sortable: true },
        ],
        statusLancamento: [
          {
            Lancado: 'success',
            null: 'warning',
            NaoLancado: 'warning',
          },
        ],
        statusDesconto: [
          {
            Descontado: 'success',
            NaoDescontada: 'warning',
            Rejeitada: 'warning',
            Renegociada: 'warning',
            PagaParcialmente: 'warning',
            null: 'warning',
          },
        ],

        // PDF
        fieldsPDF: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'codigo', label: 'N° do Contrato' },
          { key: 'valor', label: 'Valor da Parcela', formatter: (value, key, item) => formatarValorReais(value) },
          {
            key: 'vencimento',
            label: 'Data de vencimento',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'statusLancamento', label: 'Status Lancamento' },
          { key: 'statusDesconto', label: 'Status da Parcela' },
          { key: 'nomeCompleto', label: 'Nome do Tomador' },
          { key: 'matricula', label: 'Matrícula ' },
          { key: 'numeroParcelas', label: 'Parcela ' },
          { key: 'orgao', label: 'Órgão' },
          { key: 'unidadeGestora', label: 'Unidade Gestora' },
        ],
        parametroPDF: {
          dados: [],
          colunas: [],
          institutoNome: '',
        },
        sortBy: null,
        sortDesc: false,
      }
    },
    mounted() {
      this.carregaAno()
      this.carregarOrgaos()
    },
    methods: {
      carregarOrgaos() {
        if (!this.userData.institutoSelecionado) return

        useJwt
          .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.orgaoOpcao = response.data
            this.orgaoOpcao.unshift({ id: '', nome: 'TODOS' })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregaAno() {
        const dataAtual = new Date()
        const { nextMonth, nextYear } = getNextMonthAndYear(dataAtual)
        this.pesquisar.mes = nextMonth
        this.pesquisar.exercicio = nextYear
        let anoAtual = 2019
        let listaAnos = []
        for (let i = 0; i < 30; i++) {
          let anoIteracao = String(anoAtual + i)
          listaAnos.push(anoIteracao)
        }

        listaAnos = listaAnos.sort((a, b) => a - b)

        this.anoOpcao = listaAnos
      },
      async fetchLancamento(aux) {
        const res = await useJwt.pesquisar('lancamento/GetRelatorioLanc', aux).catch((error) => {
          this.isBusy = false
          console.error(error)
        })

        if (res.data.dados.length) {
          res.data.dados = res.data.dados.sort((tomadorA, tomadorB) =>
            tomadorA.nomeCompleto.localeCompare(tomadorB.nomeCompleto),
          )
        }

        return res.data
      },
      pesquisarDados() {
        this.pesquisar.currentPage = 1
        this.carregarGrid()
      },
      carregarGrid() {
        this.$refs.formPesquisar.validate().then(async (success) => {
          if (success) {
            this.isBusy = true
            const data = await this.fetchLancamento({
              ...this.pesquisar,
              institutoSelecionado: this.userData.institutoSelecionado,
              mes: this.pesquisar.mes,
              exercicio: this.pesquisar.exercicio,
              orgaoId: this.pesquisar.orgao,
              statusOpcoes: this.pesquisar.statusOpcoes === '' ? null : this.pesquisar.statusOpcoes,
            })

            this.items = data.dados
            this.totalParcela = formatarValorReais(data.totalParcela)
            this.totalRows = data.total
            this.isBusy = false
          }
        })
      },
      async exportTable(mode) {
        const currentItems = [...this.items]
        const sortedItems = sortData(currentItems, this.$refs.tableLancamento)

        if (!sortedItems || sortedItems.length === 0) {
          this.$message.error('Não há dados para exportar')
          return
        }

        const transformedItems = this.alteraStatus(sortedItems)
        if (mode === 'pdf') {
          this.parametroPDF = {
            dados: transformedItems,
            rows: transformedItems.length,
            colunas: this.fieldsPDF,
            institutoNome: this.userData.Instituto.nome,
            totalParcela: this.totalParcela,
          }
          let self = this
          setTimeout(function () {
            self.exportToPDF(self.parametroPDF)
          }, 3000)
        } else if (mode === 'excel') {
          this.exportExcel(sortedItems)
        }
      },
      exportToPDF(parametroPDF) {
        if (!parametroPDF.rows > 0) {
          this.$message.error('Não há dados para exportar')
          return
        }

        if (this.$refs.tableLancamento) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Mês: ${this.pesquisar.mes}, Exercício: ${this.pesquisar.exercicio}`
          let nomeRelatorio = `Relatório de Lancamentos`
          let informacoesInstituto = `
                  ${this.userData.Instituto.logradouro},
                  ${this.userData.Instituto.numero},
                  ${this.userData.Instituto.bairro},
                  ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                  E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportExcel(items) {
        try {
          this.$message.warning('Aguarde, estamos gerando o relatório')
          asDefault().export(items)
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o relatório', 'error')
          console.error(error)
        }
      },
      alteraStatus(e) {
        return e.map((item) => {
          return {
            ...item,
            statusLancamento:
              item.statusLancamento === 'Lancado'
                ? 'Lançado'
                : item.statusLancamento === 'Antecipado por Óbito'
                ? 'Lançado'
                : item.statusLancamento === 'Antecipado'
                ? 'Antecipado'
                : item.statusLancamento === 'Lancado'
                ? 'Lançado'
                : 'Não Lançado',
            statusDesconto:
              item.statusDesconto === 'NaoDescontada'
                ? 'Parcela Não Descontada'
                : item.statusDesconto === 'Descontado'
                ? 'Parcela Descontada'
                : item.statusDesconto === 'Rejeitada'
                ? 'Parcela Rejeitada'
                : item.statusDesconto === 'Renegociada'
                ? 'Parcela Renegociada'
                : item.statusDesconto == 'PagaParcialmente'
                ? 'Parcela Paga Parcialmente'
                : '',
          }
        })
      },
    },
  }
</script>

<style></style>
