<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <b-row>
            <b-col cols="2">
              <b-form-group label="Ano de Competência" label-for="anoDeCompetencia">
                <validation-provider #default="{ errors }" name="Ano de Competência" rules="required">
                  <v-select-pt
                    id="anoDeCompetencia"
                    name="anoDeCompetencia"
                    v-model="filtroDataDAIR.ano"
                    :options="anoOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-form-group label="Mês de Competência" label-for="mesDeCompetencia">
                <validation-provider #default="{ errors }" name="Mês de Competência" rules="required">
                  <v-select-pt
                    id="mesDeCompetencia"
                    name="mesDeCompetencia"
                    v-model="filtroDataDAIR.mes"
                    :options="mesOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Categoria" label-for="categoria">
                <b-form-select id="categoria" :options="categoriaOpcoes" v-model="categoria" />
              </b-form-group>
            </b-col>
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="2">
            <p>
              Total de Contratos: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :row-details="(row) => row.item.detailsShown"
              >
                <template #cell(categoria)="row">
                  {{ formatarCategoria(row.item.categoria) }}
                </template>
                <template #cell(detalhamento)="row">
                  <b-button size="sm" @click="row.toggleDetails" variant="primary" class="mr-2">
                    {{ row.detailsShowing ? 'Esconder' : 'Mostrar' }}
                  </b-button>
                </template>
                <template #row-details="row">
                  <b-row class="border-top mb-2 mx-3"></b-row>
                  <b-row class="mb-2 mx-3">
                    <b-table small :fields="contratoFields1" :items="[row.item.detalhamento1]"> </b-table>
                  </b-row>
                  <b-row class="border-top mb-2 mx-3"></b-row>
                  <b-row class="mb-2 mx-3">
                    <b-table small :fields="contratoFields2" :items="[row.item.detalhamento2]"> </b-table>
                  </b-row>
                </template>
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioDAIRRetrato ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataPassada, valor } from '@validations'
  import { formatarData, formatarValorReais, formatarValor, formatarValorPorcentagem } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioDAIRRetrato from './components/relatorioDAIRRetrato.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarValorReais,
      formatarValor,
      generateAndDownloadPdf,
      relatorioDAIRRetrato,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        valor: 0,
        isBusy: false,
        totalRows: 1,
        nomeRelatorio: 'Relatório DAIR',
        descricao: '',
        competencia: '',
        pesquisarPor: { id: 1, name: 'Nome' },
        parametroPDF: {
          dados: [],
          colunas: [],
          detalhamentoFields: [],
        },
        linhasTabela: [],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        currentPage: 1,
        perPage: 100,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'codigo',
        sortDesc: true,
        sortDirection: 'desc',
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: '',
        dataFinal: '',
        categoria: 'TODOS',
        options: {
          cleaveCompetencia: {
            numericOnly: true,
            delimiters: ['/'],
            blocks: [2, 4],
          },
        },
        number: {},
        colunasTabelas: [],
        currentFields: [
          {
            key: 'categoria',
            label: 'Categoria',
            sortable: true,
          },
          {
            key: 'aprovadosAnterior',
            label: 'Quantidade Anterior',
            sortable: true,
          },
          {
            key: 'aprovados',
            label: 'Contratados',
            sortable: true,
          },
          {
            key: 'quitados',
            label: 'Quitados',
            sortable: true,
          },
          {
            key: 'quantidadeAtual',
            label: 'Quantidade Atual',
            sortable: true,
          },
          {
            key: 'detalhamento',
            label: 'Detalhamento',
            sortable: true,
          },
        ],
        contratoFields1: [
          { key: 'totalAmortizado', label: 'Total Amortizado' },
          { key: 'totalAVencer', label: 'Total a Vencer' },
          { key: 'valorConcedido', label: 'Valor Concedido' },
        ],
        contratoFields2: [
          { key: 'projecaoPerdas', label: 'Projeção de Perdas' },
          { key: 'totalVencido', label: 'Total Vencidos' },
          { key: 'taxaOperacao', label: 'Taxa de Operação' },
          { key: 'capag', label: 'CAPAG' },
        ],
        categoriaOpcoes: [
          { value: 'TODOS', text: 'TODOS' },
          { value: 'Efetivo', text: 'Efetivo' },
          { value: 'Pensionista', text: 'Pensionista' },
          { value: 'Aposentado', text: 'Aposentado' },
          { value: 'Estavel', text: 'Estável' },
        ],
        filtroDataDAIR: {
          ano: new Date().getFullYear().toString(),
          mes: this.retornaMes(),
        },
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
      }
    },
    mounted() {
      if (this.userData.role !== 'master') {
        this.institutoId = this.userData.institutoId
      } else {
        this.institutoId = this.userData.institutoSelecionado
      }
      let date = new Date()
      let month = date.getMonth() + 1
      this.competencia = (date.getMonth() < 10 ? '0' + month : month) + '-' + date.getFullYear()
    },
    methods: {
      formatarCategoria(categoria) {
        switch (categoria) {
          case 'ESTAVEL':
            return 'ESTÁVEL'
          default:
            return categoria
        }
      },
      retornaMes() {
        const meses = [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ]
        const numeroMes = new Date().getMonth() + 1
        const nomeMes = meses[numeroMes - 1]
        return nomeMes
      },
      carregarGrid() {
        this.isBusy = true

        const mes = this.filtroDataDAIR.mes < 10 ? `0${this.filtroDataDAIR.mes}` : this.filtroDataDAIR.mes
        this.competencia = `${this.filtroDataDAIR.ano}${mes}`

        let filtro = {
          competencia: this.competencia.split('/').reverse().join(''),
          categoria: this.categoria,
          institutoId: this.institutoId,
        }

        useJwt
          .post('dair/getDairReportData', filtro)
          .then((response) => {
            if (response.data.error || !response.data.dairData.length > 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para visualizar!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela.length = 0
              return
            }

            this.itemsTabela = response.data.dairData.map((item) => ({
              aprovados: item.aprovados,
              aprovadosAnterior: item.aprovadosAnterior,
              categoria: item.categoria,
              quitados: item.quitados,
              quantidadeAtual: item.quantidadeAtual,
              detalhamento1: {
                rentabilidade: formatarValorPorcentagem(item.rentabilidade),
                totalAmortizado: formatarValor(item.totalAmortizado),
                totalAVencer: formatarValor(item.totalAVencer),
                valorConcedido: formatarValor(item.valorConcedido),
              },
              detalhamento2: {
                projecaoPerdas: formatarValor(item.projecaoPerdas),
                totalVencido: formatarValor(item.totalVencido),
                taxaOperacao: formatarValorPorcentagem(item.taxaOperacao),
                capag: typeof item.capag === 'string' ? item.capag.toUpperCase() : '',
              },
              unidadeGestora: response.data.unidadeGestora,
            }))

            this.totalRows = this.itemsTabela.length
            this.items = response.data
            this.linhasTabela = this.itemsTabela
            this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))

            const colunasPDF = [
              {
                key: 'categoria',
                label: 'Categoria',
              },
              {
                key: 'aprovadosAnterior',
                label: 'Quantidade Anterior',
              },
              {
                key: 'aprovados',
                label: 'Contratados',
              },
              {
                key: 'quitados',
                label: 'Quitados',
              },
              {
                key: 'quantidadeAtual',
                label: 'Quantidade Atual',
              },
              {
                key: 'unidadeGestora',
                label: 'Unidade Gestora',
              },
              {
                key: 'detalhamento',
                label: 'Detalhamento',
              },
            ]
            const itemsPDF = this.linhasTabela.map((item) => {
              return {
                ...item,
              }
            })
            let detFields = [...this.contratoFields1, ...this.contratoFields2]
            // Monta variável global de relatório PDF
            this.parametroPDF = {
              dados: itemsPDF,
              colunas: colunasPDF.filter((item) => item.key !== 'detalhamento'),
              institutoNome: this.userData.Instituto.nome,
              detalhamentoFields: detFields,
              rentabilidade: formatarValorPorcentagem(response.data.rentabilidade),
              totalAmortizado: formatarValor(response.data.totalAmortizado),
              totalAVencer: formatarValor(response.data.totalAVencer),
              valorConcedido: formatarValor(response.data.valorConcedido),
              projecaoPerdas: formatarValor(response.data.projecaoPerdas),
              taxaOperacao: formatarValorPorcentagem(response.data.taxaOperacao),
              totalVencido: formatarValor(response.data.totalVencido),
              capag: response.data.capag,
            }
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let idInstituto = this.userData.institutoSelecionado
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let relatorioPaisagem = false
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            idInstituto,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(
          this.itemsTabela.map((item) => ({
            Categoria: item.categoria,
            'Quantidade Anterior': item.aprovadosAnterior,
            Contratados: item.aprovados,
            Quitados: item.quitados,
            'Quantidade Autal': item.quantidadeAtual,
            'Unidade Gestora': item.unidadeGestora,
          })),
        )

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>
<style>
  .background-details-table {
    background-color: #f3f2f7;
    color: black;
  }
  body.dark-layout .background-details-table {
    background-color: #343d55;
    color: white;
  }
</style>
