<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="2000"
      filename="ConsigPrev - Relatório"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdfportrait"
    >
      <slot slot="pdf-content"></slot>
    </vue-html2pdf>
  </div>
</template>

<script>
  import VueHtml2pdf from 'vue-html2pdf'
  import Swal from 'sweetalert2/dist/sweetalert2.js'
  import 'sweetalert2/src/sweetalert2.scss'

  export default {
    components: {
      VueHtml2pdf,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        htmlPdfOptions: {
          margin: [0, 0, 0.35, 0],
          html2canvas: {
            scale: 1,
            useCORS: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait',
          },
        },
      }
    },
    methods: {
      hasGenerated() {
        this.sweetAlert(false)
      },
      hasStartedGeneration() {
        this.sweetAlert(true)
      },
      sweetAlert(loading) {
        Swal.fire({
          title: 'Carregando...',
          timer: !loading,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(!loading)
          },
          allowOutsideClick: false, // Bloqueia o fechamento ao clicar fora do SweetAlert
        })
      },
    },
  }
</script>

<style>
  #ExportarPDFParcelaId thead > tr > th > div {
    font-size: 0.6rem !important;
  }

  #ExportarPDFParcelaId tbody > tr > td {
    font-size: 0.55rem !important;
  }
</style>
