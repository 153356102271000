import { render, staticRenderFns } from "./RelatorioTaxaAdministracao.vue?vue&type=template&id=488563a0"
import script from "./RelatorioTaxaAdministracao.vue?vue&type=script&lang=js"
export * from "./RelatorioTaxaAdministracao.vue?vue&type=script&lang=js"
import style0 from "./RelatorioTaxaAdministracao.vue?vue&type=style&index=0&id=488563a0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports