<template>
  <div>
    <RelatorioRetrato>
      <template v-for="(pageItems, pageIndex) in paginatedItems">
        <b-table
          striped
          small
          class="ExportarPDFParcela mt-3 custom-header-style"
          :style="tableStyle(pageIndex)"
          id="ExportarPDFParcelaId"
          :head-variant="'light'"
          :items="pageItems"
          :fields="parametro.colunas"
        >
        </b-table>
        <div
          v-if="pageIndex === paginatedItems.length - 1"
          class="d-flex justify-content-end"
          style="page-break-inside: avoid; padding-top: 10px"
        >
          <h6>Valor das Parcelas: {{ parametro.totalParcela }}</h6>
        </div>
      </template>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'

  export default {
    components: {
      RelatorioRetrato,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {},
    },
    computed: {
      paginatedItems() {
        const itemsPerPage = 40 // Número de itens por página
        const pages = []
        for (let i = 0; i < this.parametro.dados.length; i += itemsPerPage) {
          pages.push(this.parametro.dados.slice(i, i + itemsPerPage))
        }
        return pages
      },
    },

    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },

    methods: {
      tableStyle(pageIndex) {
        return {
          textAlign: 'center',
          pageBreakInside: pageIndex === this.paginatedItems.length - 1 ? 'avoid' : 'auto',
          pageBreakAfter: pageIndex === this.paginatedItems.length - 1 ? 'auto' : 'always',
        }
      },
    },
  }
</script>

<style>
  .header {
    background-color: #f3f2f700;
    border-color: #4941a6;
    border: 1px solid #4941a6;
    color: #4941a6;
    font-size: 10px;
    padding: 6px 4px;
    inline-size: max-content;
    text-align: center;
  }
</style>
