<template>
  <div>
    <RelatorioRetrato>
      <template>
        <b-table
          striped
          small
          class="ExportarPDFParcela mt-3 custom-header-style"
          style="text-align: center"
          id="ExportarPDFParcelaId"
          :head-variant="'light'"
          :items="parametro.dados"
          :fields="parametro.colunas"
        >
        </b-table>

        <div v-if="parametro.dados[0]">
          <b-table-simple bordered responsive style="font-size: 10px">
            <b-tbody>
              <b-tr>
                <b-td><strong>RENTABILIDADE </strong>{{ parametro.rentabilidade }}</b-td>
                <b-td><strong>TOTAL AMORTIZADO </strong>{{ parametro.totalAmortizado }}</b-td>
                <b-td><strong>RENTABILIDADE LÍQUIDA </strong>{{ parametro.rentabilidade }}</b-td>
              </b-tr>
              <b-tr>
                <b-td><strong>TOTAL A VENCER </strong>{{ parametro.totalAVencer }}</b-td>
                <b-td><strong>PROJEÇÃO PERDAS </strong>{{ parametro.projecaoPerdas }}</b-td>
                <b-td><strong>TAXA DE OPERAÇÃO </strong>{{ parametro.taxaOperacao }}</b-td>
              </b-tr>
              <b-tr>
                <b-td><strong>VALOR CONCEDIDO </strong>{{ parametro.valorConcedido }}</b-td>
                <b-td><strong>TOTAL VENCIDO </strong>{{ parametro.totalVEncido }}</b-td>
                <b-td><strong>CAPAG </strong>{{ parametro.capag }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'

  export default {
    components: {
      RelatorioRetrato,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },
  }
</script>
