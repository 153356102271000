<template>
  <b-card>
    <h2>Relatório de geração de Arrecadação</h2>
    <validation-observer ref="formPesquisar">
      <b-form>
        <b-row>
          <b-col md="2">
            <b-form-group label="Mês" label-for="mes">
              <b-form-select id="mes" :options="mesOpcoes" v-model="pesquisar.mes" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Ano" label-for="ano">
              <v-select-pt name="ano" v-model="pesquisar.exercicio" :options="anoOpcao" label="exercicio"></v-select-pt>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Status" label-for="status">
              <b-form-select id="status" :options="statusOpcoes" v-model="pesquisar.statusOpcoes" />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Órgão" label-for="orgao">
              <v-select-pt
                name="orgao"
                v-model="pesquisar.orgao"
                :options="orgaoOpcao"
                :reduce="(option) => option.id"
                @option:selected="carregarGrid()"
                label="nome"
                v-on:keydown.enter="carregarGrid"
              >
              </v-select-pt>
            </b-form-group>
          </b-col>
          <b-col md="auto">
            <b-button
              :disabled="isBusy"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 21px; width: 160px"
              @click="carregarGrid()"
            >
              {{ !isBusy ? 'Pesquisar' : '' }}
              <b-spinner small v-if="isBusy" />
            </b-button>
          </b-col>
          <b-col md="auto">
            <template>
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-top: 21px; width: 160px">
                    Exportar
                  </b-button>
                </template>
                <b-dropdown-item href="#" @click="exportTable('pdf')">
                  <feather-icon icon="FileTextIcon" size="16" />
                  <span class="align-middle ml-50">PDF</span>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="exportTable('excel')">
                  <feather-icon icon="CheckCircleIcon" size="16" />
                  <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div v-if="items.length > 0" style="margin-top: 0.6rem">
      <b-row>
        <b-col md="6">
          <p>
            Total de quantidade apresentada: <b-badge variant="primary"> {{ total }}</b-badge>
          </p>
        </b-col>
      </b-row>
    </div>
    <b-table
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative"
      id="tableGRPE"
      ref="tableGRPE"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="isBusy"
      @change="currentPage = $event"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(createdAt)="row"> {{ formatarData(row.item.createdAt) }} </template>>
      <template #cell(status)="finanrow">
        <b-badge pill :variant="status[0][finanrow.item.status]">
          {{
            finanrow.item.status == 'Gerado'
              ? 'Gerado'
              : finanrow.item.status == 'NaoGerado'
              ? 'Não Gerado'
              : finanrow.item.status == 'Pago'
              ? 'Pago'
              : 'Expirado'
          }}
        </b-badge>
      </template>
    </b-table>
    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totals.rows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <div>
      <relatorioFinanceiroGRPERetrato ref="exportarPDF" :parametro="parametroPDF" />
    </div>
  </b-card>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataGlobal, formatarValorReais } from '@/libs/utils'
  import { getNextMonthAndYear } from '@/utils/Functions/Datas/Datas'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import VueHtml2pdf from 'vue-html2pdf'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import relatorioFinanceiroGRPERetrato from './components/relatorioFinanceiroGRPERetrato.vue'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import { asDefault } from './components/exportTableGRPE'
  import { sortData } from '@/utils/geradorPDF/sortMethod'

  export default {
    components: {
      relatorioFinanceiroGRPERetrato,
      BCard,
      BPagination,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      BCardCode,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      BDropdown,
      BDropdownItem,
      formatarData,
      formatarValorReais,
      generateAndDownloadPdf,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarData,
        isBusy: false,

        //Search
        pesquisar: {
          tipoOpcoes: 1,
          perPage: 100,
          currentPage: 1,
          mes: null,
          exercicio: null,
          statusOpcoes: '',
          orgao: null,
        },
        orgaoOpcao: [],
        anoOpcao: [],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        statusOpcoes: [
          { value: '', text: 'Todos' },
          { value: 'Pago', text: 'Pago' },
          { value: 'Gerado', text: 'Gerado' },
          { value: 'NaoGerado', text: 'Não Gerado' },
        ],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },

        //table
        pageOptions: [10, 25, 50, 100],
        items: [],
        totals: {
          rows: 0,
        },
        sortBy: null,
        sortDesc: false,
        fields: [
          {
            key: 'dataCompetenciaConvertida',
            label: 'Competência',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
            sortable: true,
          },
          {
            key: 'quantidadeContratos',
            label: 'Quantidade De Contratos',
            formatter: (value, key, item) => {
              return this.formatarContrato(value)
            },
            sortable: true,
          },

          {
            key: 'dataVencimento',
            label: 'Data de vencimento',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
            sortable: true,
          },
          { key: 'diaDeRepasse', label: 'Dia de Repasse', sortable: true },
          { key: 'nomeOrgao', label: 'Órgão', sortable: true },
          { key: 'status', label: 'Status Arrecadação', sortable: true },
        ],
        statusLancamento: [
          {
            Lançado: 'success',
            'Não Lançado': 'warning',
          },
        ],
        status: [
          {
            Gerado: 'success',
            NaoGerado: 'warning',
            Expirado: 'secondary',
            Pago: 'info',
          },
        ],

        // PDF
        parametroPDF: {
          dados: [],
          colunas: [],
          institutoNome: '',
        },
        fieldsPDF: [
          {
            key: 'dataCompetenciaConvertida',
            label: 'Competência',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          {
            key: 'quantidadeContratos',
            label: 'Qtde de Contratos',
            formatter: (value, key, item) => {
              return this.formatarContrato(value)
            },
          },
          {
            key: 'dataVencimento',
            label: 'Data de vencimento',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'diaDeRepasse', label: 'Dia de Repasse' },
          { key: 'nomeOrgao', label: 'Órgão' },
          { key: 'status', label: 'Status Arrecadação' },
          { key: 'unidadeGestora', label: 'Unidade Gestora' },
        ],
        total: 0,
      }
    },
    mounted() {
      this.carregaAno()
      this.carregarOrgaos()
    },
    methods: {
      carregarOrgaos() {
        if (!this.userData.institutoSelecionado) return

        useJwt
          .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.orgaoOpcao = response.data
            this.orgaoOpcao.unshift({ id: '', nome: 'TODOS' })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregaAno() {
        const dataAtual = new Date()
        const { nextMonth, nextYear } = getNextMonthAndYear(dataAtual)
        this.pesquisar.mes = nextMonth
        this.pesquisar.exercicio = nextYear
        let anoAtual = 2019
        let listaAnos = []
        for (let i = 0; i < 30; i++) {
          let anoIteracao = String(anoAtual + i)
          listaAnos.push(anoIteracao)
        }

        listaAnos = listaAnos.sort((a, b) => a - b)

        this.anoOpcao = listaAnos
      },
      async fetchGrpe(parametro) {
        const response = await useJwt.pesquisar('financeiro/GetRelatorioGRPE', parametro).catch((error) => {
          this.isBusy = false
          console.error(error)
        })

        return response.data
      },
      carregarGrid() {
        this.$refs.formPesquisar.validate().then(async (success) => {
          if (success) {
            this.isBusy = true
            const response = await this.fetchGrpe({
              ...this.pesquisar,
              mes: this.pesquisar.mes,
              exercicio: this.pesquisar.exercicio,
              orgaoId: this.pesquisar.orgao,
              statusOpcoes: this.pesquisar.statusOpcoes === '' ? null : this.pesquisar.statusOpcoes,
              institutoSelecionado: this.userData.institutoSelecionado,
            })

            if (!response.dados.length) {
              this.$message.warning('Nenhum registro foi encontrado!')
            }

            this.items = response.dados
            this.total = response.total
            this.totals = {
              rows: response.total,
              geradoGRPE: response.gerado,
              naoGeradoGRPE: response.naoGerado,
            }
            this.isBusy = false
            response.dados.map((item) => {
              if (item.statusLancamento) {
                item.statusLancamento = item.statusLancamento == 'Lancado' ? 'Lançado' : 'Não Lançado'
              }
            })
          }
        })
      },
      async exportTable(mode) {
        const currentItems = [...this.items]
        const sortedItems = sortData(currentItems, this.$refs.tableGRPE)

        if (!sortedItems || sortedItems.length === 0) {
          this.$message.error('Não há dados para exportar')
          return
        }

        sortedItems.map((item) => {
          if (item.statusLancamento) {
            item.statusLancamento = item.statusLancamento == 'Lancado' ? 'Lançado' : 'Não Lançado'
          }
        })

        if (mode === 'pdf') {
          this.parametroPDF = {
            dados: sortedItems,
            rows: sortedItems.length,
            colunas: this.fieldsPDF,
            institutoNome: this.userData.Instituto.nome,
          }
          let self = this
          setTimeout(function () {
            self.exportToPDF(self.parametroPDF)
          }, 3000)
        } else if (mode === 'excel') {
          this.exportExcel(sortedItems)
        }
      },
      exportToPDF(parametroPDF) {
        if (!parametroPDF.rows > 0) {
          this.$message.error('Não há dados para exportar')
          return
        }

        if (this.$refs.tableGRPE) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Mês: ${this.pesquisar.mes}, Exercício: ${this.pesquisar.exercicio}`
          let nomeRelatorio = `Relatório de Arrecadação`
          let informacoesInstituto = `
                    ${this.userData.Instituto.logradouro},
                    ${this.userData.Instituto.numero},
                    ${this.userData.Instituto.bairro},
                    ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                    E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                  `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportExcel(items) {
        try {
          this.$message.warning('Aguarde, estamos gerando o relatório')
          asDefault().export(items)
        } catch (error) {
          this.$message.error('Ocorreu um erro ao gerar o relatório', 'error')
          console.error(error)
        }
      },
      formatarContrato(value) {
        let aux = ''
        aux = value.toString().replace(/\[|\]|"/g, '')
        return aux
      },
    },
    watch: {
      'pesquisar.currentPage': function () {
        this.carregarGrid()
      },
      'pesquisar.perPage': function () {
        this.carregarGrid()
      },
    },
  }
</script>

<style></style>
