function getSortingParams(table) {
  return {
    sortBy: table.$attrs['sort-by'] || table.sortBy,
    sortDesc: table.$attrs['sort-desc'] || table.sortDesc,
  }
}
export function sortData(data, table) {
  const { sortBy, sortDesc } = getSortingParams(table)

  if (!sortBy) return data
  return [...data].sort((a, b) => {
    let aVal = getNestedValue(a, sortBy)
    let bVal = getNestedValue(b, sortBy)

    aVal = normalizeValue(aVal)
    bVal = normalizeValue(bVal)
    if (aVal === null && bVal !== null) return sortDesc ? 1 : -1
    if (bVal === null && aVal !== null) return sortDesc ? -1 : 1
    if (aVal === null && bVal === null) return 0
    if (sortDesc) {
      return aVal < bVal ? 1 : aVal > bVal ? -1 : 0
    }
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
  })
}
function normalizeValue(value) {
  if (value === null || value === undefined) return null
  if (typeof value === 'string' && value.includes('/')) {
    const [part1, part2] = value.split('/').map((part) => Number(part.trim()))
    return part1 + part2 / 100
  }
  if (typeof value === 'string' && !isNaN(Date.parse(value))) {
    return new Date(value).getTime()
  }
  if (!isNaN(Number(value))) {
    return Number(value)
  }
  if (typeof value === 'string') {
    return value.toLowerCase()
  }
  return value
}
function getNestedValue(obj, path) {
  return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), obj)
}
